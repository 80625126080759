import {
  IWindyApi,
  L,
  TVesselTrack,
  TVesselTrackExt,
  TVesselTrackPoint
} from 'types';

/**/
export type TBasicTrackOptions = {
  map: IWindyApi['map'];
  onClick: (
    vesselId: number,
    point: TVesselTrackPoint,
    latlng: L.LatLng
  ) => void;
  path: TVesselTrack | TVesselTrackExt;
  showTooltip: boolean;
};

export type TSimpleTrackOptions = TBasicTrackOptions & {
  color: string;
};

export type TSpeedTrackOptions = TBasicTrackOptions & {
  showDots: boolean;
};

export type TFishingTrackOptions = TBasicTrackOptions & {
  fishingActivitySpeed: [number, number];
};

/**
 *
 */
export type Chunk = {
  color: string;
  data: TVesselTrackPoint[];
};

export enum ESpeed {
  'S0',
  'S1',
  'S2',
  'S3',
  'S4',
  'S5',
  'S6',
  'S7',
  'S8'
}

export enum ESpeedFishing {
  'S0',
  'S1'
}

export const COLOR_MAP = {
  [ESpeed.S0]: '#0216C8',
  [ESpeed.S1]: '#4E74FC',
  [ESpeed.S2]: '#4DB3FF',
  [ESpeed.S3]: '#00E6B8',
  [ESpeed.S4]: '#B3F95B',
  [ESpeed.S5]: '#FFFF00',
  [ESpeed.S6]: '#FFD525',
  [ESpeed.S7]: '#FF8717',
  [ESpeed.S8]: '#FF0000'
};

export const COLOR_MAP_FISHING = {
  [ESpeed.S0]: 'grey',
  [ESpeed.S1]: '#B3F95B'
};

// The value, that should be surpassed to display dots.
export const ZOOM_THRESHOLD = 9;

// General fishing activity speed range.
export const FISHING_ACTIVITY_RANGE = [0.3, 7.5];

// Maps fishing activity speed range against vessel type.
export const FISHING_SPEED_MAP = {
  2: [0.3, 7.5], // Pelagic
  3: [1, 5], // Trawler
  4: [1, 5], // Freezing trawlers
  5: [1, 5], // Long liner
  6: [0.3, 5], // Crabs and Shellfish
  8: [1, 5], // Krill
  9: [1, 5], // Tuna
  10: [1, 5] // Coast
};

// Fishing vessel type IDs.
export const FISHING_VESSEL_TYPES = Object.keys(FISHING_SPEED_MAP).map((x) =>
  parseInt(x)
);
