import React from 'react';
import { connect } from 'react-redux';

import './ThemeToggle.scss';
import { GA } from 'services';
import { setTheme } from 'store/misc/miscActions';
import { ThemeSwitch } from './ThemeSwitch';
import { TState } from 'store';

/**
 *
 */
const mapDispatchToProps = (dispatch) => ({
  setTheme: (isDark: boolean) => {
    dispatch(setTheme(isDark));
    isDark && GA.reportDarkMode();
  }
});

const mapStateToProps = ({ misc: { theme } }: TState) => ({
  isDark: theme.isDark
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSwitch);
