import { EMapIceLayer, EMapLayer, L, TLayerInfo, TLayerValues } from 'types';

export enum EColorDescriptor {
  COUNTRIES = 'COUNTRIES',
  VESSEL_TYPES = 'VESSEL_TYPES'
}

export type TMapOptions = {
  center: L.LatLng;
  colorDescriptor: EColorDescriptor;
  currentMapLayer: EMapLayer;
  iceLayers: EMapIceLayer[];
  isDarkMode: boolean;
  isPolyTrack: boolean;
  layerDateLabel: string;
  layerInfo: TLayerInfo;
  layerValuesMap: Record<EMapLayer, TLayerValues>;
  showCard: boolean;
  showPicture: boolean;
  showShipName?: boolean;
  zoom: number;
};
